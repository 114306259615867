import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class ThemeService {
    private _darkTheme: Subject<boolean> = new Subject<boolean>();
    isDarkTheme;
	constructor() {
		this.setDarkTheme(this.getFromLocal());
	}
    setDarkTheme(isDarkTheme: boolean) {
		console.log(isDarkTheme);
		this.setLocat(isDarkTheme);
		this.isDarkTheme = isDarkTheme;
	}
	
	setLocat(isDarkTheme){
		localStorage.setItem('dark-theme-tc-mind', isDarkTheme);
	}

	getFromLocal(): boolean {
		return localStorage.getItem('dark-theme-tc-mind') == 'true';
	}
}
