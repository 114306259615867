import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AppService {

    private config: Object = null;
    private env:    Object = null;
	public inited = false;
	public isIe = false;
	public loading = true;
    constructor(
		private _http: HttpClient
	) {
		this.browser();
	}
	
	public browser(){
		let ba = navigator.userAgent;
		this.isIe = ba.indexOf('MSIE ') > -1 || ba.indexOf("Trident/") > -1;
	}

	public checkIfIe(): boolean{
		return this.isIe;
	}

    /**
     * Use to get the data found in the second file (config file)
     */
    public getConfig(key: any) {
        return this.config[key];
    }

    /**
     * Use to get the data found in the first file (env file)
     */
    public getEnv(key: any) {
        return this.env[key];
    }

    /**
     *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
     *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json')
     */
    public load() {
        return new Promise((resolve, reject) => {
            this._http.get('./assets/app/config/env.json').subscribe( (envResponse) => {
                this.env = envResponse;
				let request: any = null;
                switch (envResponse['env']) {
                    case 'production': {
                        request = this._http.get('./assets/app/config/' + envResponse['env'] + '.json');
                        break;
                    }
                    case 'local': {
                        request = this._http.get('./assets/app/config/' + envResponse['env'] + '.json');
                        break;
                    }
                }
                request
                    .subscribe((responseData) => {
						this.config = responseData;
						this.inited = true;
                        resolve(true);
                    }, (error: any) => {
                        resolve(error);
                        //return Observable.throw(error.json().error || 'Server error');
                    });
            }, (error: any): any => {
                resolve(true);
                //return Observable.throw(error.json().error || 'Server error');
            });

        });
    }
}
