import { NgModule, APP_INITIALIZER } from "@angular/core";
import { AppComponent } from "./app.component";
import { LayoutModule } from "./modules/layout/layout.module";
import { MatButtonModule } from "@angular/material/button";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthService } from "./modules/auth/services/auth.service";
import { MatToolbarModule } from "@angular/material";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppInterceptor } from "./interceptors/_app.interceptor";
import { JwtInterceptor } from "./interceptors/_jwt.interceptor";
import { ResponseInterceptor } from "./interceptors/_response.interceptor";
import { AppService } from "./services/app.service";
import { ThemeService } from "./modules/layout/services/theme.service";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { TcConfirmModule } from "./modules/tc-confirm/tc-confirm.module";
import { TcSnackModule } from "./modules/tc-snack/tc-snack.module";
import { PreloaderModule } from './modules/preloader/preloader.module';
import { SafeHtmlPipe } from './pipes/safe-html';
import { BreadCrumbService } from './services/breadcrumb.service';

export function initConfig(config: AppService) {
	return () => config.load();
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserAnimationsModule,
		MatProgressBarModule,
		TcConfirmModule,
		TcSnackModule,
		MatToolbarModule,
		LayoutModule,
		PreloaderModule,
		MatButtonModule,
		AppRoutingModule,
		HttpClientModule
	],
	providers: [
		ThemeService,
		AppService,
		{
			provide: APP_INITIALIZER,
			useFactory: initConfig,
			deps: [AppService],
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AppInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ResponseInterceptor,
			multi: true
		},
		AuthService,
		BreadCrumbService
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
