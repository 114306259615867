import { EventEmitter, Injectable } from "@angular/core";
import { Event, NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class SideNavService {
    public appDrawer: any;
    public currentUrl = new BehaviorSubject<string>(undefined);
	public isOpen = true;
    constructor(private router: Router) {
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.currentUrl.next(event.urlAfterRedirects);
            }
        });
    }

    public closeNav(): void {
        this.appDrawer.close();
    }

    public openNav(): void {
        this.appDrawer.open();
	}
	
	public toggleNav(): void {
		if (this.isOpen) {
			this.appDrawer.close();
		} else {
			this.appDrawer.open();
		}

		this.isOpen = !this.isOpen;
	}
}
