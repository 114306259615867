import { Component, OnInit, Input, HostBinding } from "@angular/core";
import {
    trigger,
    state,
    transition,
    animate,
    style
} from "@angular/animations";
import { SideNavService } from "../../services/sidenav.service";
import { Router } from "@angular/router";

@Component({
    selector: "app-menu-item",
    templateUrl: "./menu-item.component.html",
    styleUrls: ["./menu-item.component.scss"],
    animations: [
        trigger("indicatorRotate", [
            state("collapsed", style({ transform: "rotate(0deg)" })),
            state("expanded", style({ transform: "rotate(180deg)" })),
            transition(
                "expanded <=> collapsed",
                animate("225ms cubic-bezier(0.4,0.0,0.2,1)")
            )
        ])
    ]
})
export class MenuItemComponent implements OnInit {
    expanded: boolean;
    @HostBinding("attr.aria-expanded") ariaExpanded = true;
    @Input() item;
    @Input() depth: number;

    constructor(public navService: SideNavService, public router: Router) {
        if (this.depth === undefined) {
            this.depth = 0;
        }
    }

    ngOnInit() {
        this.navService.currentUrl.subscribe((url: string) => {
            if (this.item.route && url) {
                this.expanded = true;
                this.ariaExpanded = this.expanded;
            }
        });
    }

    onItemSelected(item) {
        if (!item.children || !item.children.length) {
            this.router.navigate([item.route]);
            //this.navService.closeNav();
        }
        if (item.children && item.children.length) {
            this.expanded = true;
        }
    }
}
