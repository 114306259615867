import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TcConfirmService } from '../tc-confirm.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-confirm-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss']
})
export class BoxComponent implements OnInit, OnDestroy {
	
	sub: Subscription[] = [];
	@ViewChild('confirmBox', {static: false}) confirmBox;
	constructor(public dialog: MatDialog, public _confirm: TcConfirmService) {}

	ngOnInit(): void {
		let subscriber = this._confirm.boxEvents.subscribe($event => {
			if ($event.event === 'show') {
				this.openDialog();
			}
		});
		this.sub.push(subscriber);
	}
  
	openDialog(): void {
	  const dialogRef = this.dialog.open(this.confirmBox, {
		width: '400px',
		data: {name: 'test'}
	  });
  
	  dialogRef.afterClosed().subscribe(result => {

	  });
	}

	confirm() {
		this.dialog.closeAll();
		this._confirm.confirmed();
	}

	ngOnDestroy(): void {
		for (let x in this.sub) {
			this.sub[x].unsubscribe();
		}
	}

}
