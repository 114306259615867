import {EventEmitter, Injectable} from '@angular/core';
import {Preloader} from '../models/preloader';

@Injectable({
    providedIn: 'root'
})
export class PreloaderService {
    state: EventEmitter<any> = new EventEmitter();
    show(options: Preloader = {}) {
        this.state.emit({show: true, options: options});
    }
    hide() {
        this.state.emit({show: false});
    }
}
