import { Component, ViewEncapsulation, OnInit } from "@angular/core";
import { AuthService } from "./modules/auth/services/auth.service";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";
import { ThemeService } from "./modules/layout/services/theme.service";
import { BreadCrumbService } from './services/breadcrumb.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
	title = "PIXLVISN";
	isDarkTheme: any;
	path;
	constructor(public _auth: AuthService, 
		private _router: Router, 
		public themeService: ThemeService,
		public _bread: BreadCrumbService
	){
		if (!_auth.check()) {
			this._router.navigateByUrl('/auth');
		}

		this._auth._succesLogin.subscribe(action => {
			this._router.navigateByUrl('/');
		})

		_auth._succesLogout.subscribe(action => {
			this._router.navigateByUrl('/auth');
		})

		console.log("here we build")

		_router.events.subscribe(event => {
			if(event instanceof NavigationEnd) {
				console.log(_router.url.split("/"));
				this.path = _router.url.split("/")[1];
			}
		})
	}

	ngOnInit(): void {
		this.isDarkTheme = this.themeService.isDarkTheme;
	}
}
