import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UserModel } from "src/app/modules/auth/models/user.model";
import { Observable } from "rxjs";
import { AppService } from "src/app/services/app.service";

@Injectable()
export class AuthService {
    public _succesLogin: EventEmitter<any> = new EventEmitter<UserModel>();
	public _succesLogout: EventEmitter<any> = new EventEmitter<any>();
	public _tertChanged: EventEmitter<any> = new EventEmitter<any>();
    public _errorLogin: EventEmitter<any> = new EventEmitter<any>();
    public userModel: UserModel | Boolean;
	public token: string;
    constructor(private _http: HttpClient, private _app: AppService) {}

	/**
	 * Post 
	 * @param request 
	 */
    forgetPassword(request: {
        email: string;
        password: string;
    }): Observable<any> {
        return this._http.post("/forgetPassword", request);
    }

	/**
	 * Post requested data to server, check and emit state of login
	 * @param request 
	 */
    login(request: {auth: { EMAIL: string; PASSWORD: string, recaptcha: string, remember: boolean }}): void {
        this._http.post("login/auth", request).subscribe(response => {
			console.log(response);
			if (response['token']) {
				this.setToken(response['token']);
				this._succesLogin.emit(this.user());
			} else {
				this._errorLogin.emit(response['error']);
			}
		});
	}
	
	/**
	 * Check if userModel isset
	 */
    check(): Boolean {
        if (this.user()) {
            return true;
        }
        return false;
    }

	/**
	 * Get user information from model or
	 * decode token from local storage 
	 */
    user(): UserModel | Boolean {
        if (this.userModel instanceof UserModel) {
            return this.userModel;
        } else {
			this.getToken();
            return (this.userModel = this.decodeToken());
        }
    }

	/**
	 * Set token to local storage
	 * @param token 
	 */
    setToken(token: string): void {
        this.token = token;
        localStorage.setItem(
            this._app.getConfig("name") + "-token",
            this.token
        );
    }

	/**
	 * Get token from local storage
	 */
    getToken(): String {
		if (this._app.inited) {
			return (this.token = localStorage.getItem(
				this._app.getConfig("name") + "-token"
			));
		}
	}
	
	/**
	 * Decode token and try to return
	 * as UserModel
	 */
    decodeToken() :UserModel | boolean {
        try {
            const base64Url = this.token.split(".")[1];
            const base64 = base64Url.replace("-", "+").replace("_", "/");
            const payload = JSON.parse(window.atob(base64));
            return <UserModel> payload;
        } catch (err) {
            return false;
        }
	}
	
	/**
	 * Remove token from local storage
	 * and emit succefuly logout
	 */
    logout() {
		localStorage.removeItem(this._app.getConfig("name") + "-token");
        this._succesLogout.emit();
    }
}
