import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoxComponent } from './box/box.component';
import { MatDialogModule, MatButtonModule } from '@angular/material';
import { TcConfirmService } from './tc-confirm.service';

@NgModule({
  declarations: [BoxComponent],
  imports: [
	CommonModule,
	MatDialogModule,
	MatButtonModule
  ],
  exports: [BoxComponent], 
  providers: [TcConfirmService]
})
export class TcConfirmModule { }
