import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ToolbarComponent } from "./components/toolbar/toolbar.component";
import { SidenavComponent } from "./components/sidenav/sidenav.component";
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import { MatIconModule, MatSlideToggleModule } from "@angular/material";
import {MatListModule} from '@angular/material/list';
import { SideNavService } from "./services/sidenav.service";
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatMenuModule} from '@angular/material/menu';


@NgModule({
    imports: [CommonModule, MatToolbarModule, MatSidenavModule, MatIconModule, MatListModule, FlexLayoutModule,  MatSlideToggleModule, MatMenuModule],
	declarations: [ToolbarComponent, SidenavComponent, MenuItemComponent],
	providers: [SideNavService],
	exports: [ToolbarComponent, SidenavComponent]
})
export class LayoutModule {}

