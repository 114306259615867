import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
	{
		path: "inbox",
		loadChildren: "./modules/inbox/inbox.module#InboxModule"
	},
	{
        path: "articles",
        loadChildren: "./modules/articles/articles.module#ArticlesModule"
	},
    {
        path: "auth",
        loadChildren: "./modules/auth/auth.module#AuthModule"
	},
	{
        path: "categories",
        loadChildren: "./modules/category/category.module#CategoryModule"
        },
        {
        path: "orders",
        loadChildren: "./modules/orders/orders.module#OrdersModule"
        },
	{
        path: "events",
        loadChildren: "./modules/events/events.module#EventsModule"
	},
	{
        path: "gallery",
        loadChildren: "./modules/gallery/gallery.module#GalleryModule"
	},
	{
        path: "staff",
        loadChildren: "./modules/teachers/teachers.module#TeachersModule"
	},
	{
		path: "reviews",
		loadChildren: "./modules/students/students.module#StudentsModule"
	},
	{
		path: "seo",
		loadChildren: "./modules/seo/seo.module#SeoModule"
	},
	{
		path: "email-templates",
		loadChildren: "./modules/email-templates/email-templates.module#EmailTemplatesModule"
	},
	{
		path: "videos",
		loadChildren: "./modules/videos/videos.module#VideosModule"
	},
	{
		path: "faq",
		loadChildren: "./modules/faq/faq.module#FaqModule"
	},
	{
		path: "user",
		loadChildren: "./modules/user/user.module#UserModule"
	},
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
