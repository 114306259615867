import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { TcSnackService } from '../tc-snack.service';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-snack',
  templateUrl: './snack.component.html',
  styleUrls: ['./snack.component.scss']
})
export class SnackComponent implements OnInit {

	sub: Subscription[] = [];
	@ViewChild('snackBox', {static: false, read: TemplateRef}) snackBox;

	config: MatSnackBarConfig = {
		duration: 5000,
		verticalPosition: 'top',
		horizontalPosition: 'end'
	};
	message: any;

	constructor(public _snackBar: MatSnackBar, public _confirm: TcSnackService) {}

	ngOnInit(): void {
		let subscriber = this._confirm.boxEvents.subscribe($event => {
			if ($event.event === 'show') {
				this.message = $event.message;
				this.openDialog();
			}
		});
		this.sub.push(subscriber);
	}
  
	openDialog(): void {
		console.log(this._snackBar);
		console.log(this.snackBox);
	  
	  const snack = this._snackBar.openFromTemplate(this.snackBox,this.config);
	
	  //dialogRef.afterClosed().subscribe(result => {

	  //});
	}

	confirm() {
		//this.dialog.closeAll();
		this._confirm.confirmed();
	}

	ngOnDestroy(): void {
		for (let x in this.sub) {
			this.sub[x].unsubscribe();
		}
	}
}
