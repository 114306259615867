export class UserModel{
	id: number;
	username: string;
    email: string;
    fname: string;
    lname: string;
    token: string;
    user_type:ROLE;
}

enum ROLE {
    "admin",
    "normal",
}