import { Component, OnInit, Input, ViewChild, AfterViewInit } from "@angular/core";
import { trigger, state, transition, animate, style } from "@angular/animations";
import { SideNavService } from "../../services/sidenav.service";
import { Router } from "@angular/router";
import { AuthService } from "../../../auth/services/auth.service";

@Component({
    selector: "app-sidenav",
    templateUrl: "./sidenav.component.html",
	styleUrls: ["./sidenav.component.scss"],
	animations: [
		trigger('indicatorRotate', [
		  state('collapsed', style({transform: 'rotate(0deg)'})),
		  state('expanded', style({transform: 'rotate(180deg)'})),
		  transition('expanded <=> collapsed',
			animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
		  ),
		])
	]
})
  /*
		  children: [
			{
			  displayName: 'Underwriter Admin',
			  iconName: 'fas fa-cogs',
			  route: '/uw',
			}
		  ]
		  */
export class SidenavComponent implements OnInit, AfterViewInit {
	user: any;
	@Input() pageContent;
	@Input() visible;
	@ViewChild('appDrawer', {static: true}) appDrawer;
	navItems= [
		{
			displayName: 'Inbox',
			iconName: 'fas fa-envelope',
			expanded: false,
			route: '/inbox'
		},
		{
		  displayName: 'Articles',
		  iconName: 'fas fa-thumbtack',
		  route: '/articles',
		  expanded: true,

		},
		{
			displayName: 'Categories',
			iconName: 'fas fa-list',
			route: '/categories',
			expanded: true
		},
		{
			displayName: 'Events',
			iconName: 'fas fa-calendar-alt',
			route: '/events',
			expanded: true
		},
		{
			displayName: 'Orders',
			iconName: 'fas fa-shopping-cart',
			route: '/orders',
			expanded: true
		},
		{
			displayName: 'Galleries',
			iconName: 'fas fa-images',
			route: '/gallery',
			expanded: true
		},
		{
			displayName: 'Staff',
			iconName: 'fas fa-users',
			route: '/staff',
			expanded: true
		},
		{
			displayName: 'Reviews',
			iconName: 'fas fa-comments',
			route: '/reviews',
			expanded: true
		},
		{
			displayName: 'Seo',
			iconName: 'fas fa-chart-line',
			route: '/seo',
			expanded: true
		},
		{
			displayName: 'Email Templates',
			iconName: 'fas fa-cubes',
			expanded: false,
			route: '/email-templates'
		},
		{
			displayName: 'Videos',
			iconName: 'fas fa-video',
			expanded: false,
			route: '/videos'
		},
		{
			displayName: 'FAQ',
			iconName: 'fas fa-life-ring',
			expanded: false,
			route: '/faq'
		},
		{
			displayName: 'Users',
			iconName: 'fas fa-users',
			expanded: false,
			route: '/user'
		},
	  ];


    constructor(private _sideNav: SideNavService, public router: Router, private _auth: AuthService) {
		this.user = _auth.user();
	}

    ngOnInit() {

	}

	ngAfterViewInit(): void {
		this._sideNav.appDrawer = this.appDrawer;
	}
}
