import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../../auth/services/auth.service";
import { SideNavService } from "../../services/sidenav.service";
import { ThemeService } from "../../services/theme.service";
import { Observable } from "rxjs";

@Component({
    selector: "app-toolbar",
    templateUrl: "./toolbar.component.html",
    styleUrls: ["./toolbar.component.scss"]
})
export class ToolbarComponent implements OnInit {
	isDarkTheme: Observable<boolean>;
    constructor(private _auth: AuthService, public _sideNav: SideNavService, private _themeService: ThemeService) {}

    ngOnInit() {
		this.isDarkTheme = this._themeService.isDarkTheme;
	}

    public logout(): void {
        this._auth.logout();
	}
	toggleDarkTheme(isDarkTheme: boolean) {
		this._themeService.setDarkTheme(isDarkTheme);
	}
}
