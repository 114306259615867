import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AppService } from 'src/app/services/app.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

    constructor(private _appConfig: AppService) { 
	}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.indexOf('assets') === -1 ) {
			this._appConfig.loading = true;
            const url = this._appConfig.getConfig('host');
            req = req.clone({
                url: url + req.url
            });
        }
        req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
        return next.handle(req);
    }
}
