import {Component, OnInit} from '@angular/core';
import {PreloaderService} from '../../services/PreloaderService';
import {Preloader} from '../../models/preloader';
import { Router, NavigationEnd } from '@angular/router';

@Component({
    selector: 'app-preloader',
    templateUrl: './preloader.component.html',
    styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent implements OnInit {
    showPreloader;
    options: Preloader;
    routeShown = null;
    constructor(private _preloaderService: PreloaderService,private router: Router) {
		
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd && this.routeShown !== this.router.url && this.showPreloader == true) {
                if (this.routeShown != null) {
                    _preloaderService.hide();
                    this.routeShown = null;
                    this.showPreloader = false;
                }
            }
            this.routeShown = this.router.url;
        });

    }

    ngOnInit() {
        this._preloaderService.state.subscribe(resp => {
            if (resp.show) {
                this.options = resp.options;
                this.showPreloader = true;
            } else {
                this.showPreloader = false;
            }
        });
    }

}
