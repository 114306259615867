import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatButtonModule } from '@angular/material';
import { TcSnackService } from './tc-snack.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackComponent } from './snack/snack.component';

@NgModule({
  declarations: [SnackComponent],
  imports: [
	CommonModule,
	MatDialogModule,
	MatButtonModule,
	MatSnackBarModule
  ],
  exports: [SnackComponent], 
  providers: [TcSnackService]
})
export class TcSnackModule { }
