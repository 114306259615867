import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../modules/auth/services/auth.service';
import { AppService } from '../services/app.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

	constructor(private _router: Router, private _appConfig: AppService, private _auth: AuthService) {

	}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse && req.url.indexOf('assets') === -1 ) {
				return event.clone({
					body: this.parseResponse(event.body)
				});
			}
			return event;
        }));

	}

	parseResponse(body): void{
		this._appConfig.loading = false;
		/*
		if (body.msg.code == 102) {
			this._auth.logout();
            this._router.navigateByUrl("/auth");
			return;
		}
		if (
			body.msg.code == 101 || 
			body.msg.code == 1 || 
			body.msg.code == 202 || 
			body.msg.code == 205
		) {
			return body.data;
		}

		if (body.msg.code == 10) {
			this.download(body.data);
		}
		*/
	}

	download(data): void{
		let a = window.document.createElement('a');
		a.href = window.URL.createObjectURL(new Blob(
			this.s2ab(window.atob(data.file_contentb64)),{type: data.content_type}));
		a.download = data.file_name;

		// Append anchor to body.
		document.body.appendChild(a);
		a.click();

		// Remove anchor from body
		document.body.removeChild(a)
	}

	s2ab(s) {
		var buf = new ArrayBuffer(s.length);
		var view = new Uint8Array(buf);
		for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
		return [buf];
	}
}
