export class BreadCrumbService{

	items;
	url = '/';
	name;
	setItems(items) {
		console.log(items);
		this.items = items;
	}

	addItem(item) {
		this.items.push(item);
	}

	getItems() {
		return this.items;
	}

	setHomeUrl(url) {
		this.url = url;
	}

	getHomeUrl() {
		return this.url;
	}

	setHomeName(name) {
		this.name = name;
	}

	removeAfterIndex(index) {
		this.items.splice(index + 1, this.items.length - (index + 1) );
	}

}