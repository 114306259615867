import {Injectable, Injector} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/do';
import { AuthService } from '../modules/auth/services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

	constructor(private _authService: AuthService) {

	}
    intercept(request: HttpRequest < any > , next: HttpHandler): Observable < HttpEvent < any >> {
        const token = this._authService.getToken();
        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            });
        }

        return next.handle(request).do(event => {
            if (event instanceof HttpResponse) { 

            }
        });
    }
}
