import { Injectable, EventEmitter } from "@angular/core";

@Injectable()
export class TcConfirmService {

	public boxEvents = new EventEmitter();
	public confirmedEvent = new EventEmitter();

	private _result: Promise<any>;
    private _resolve: any;
	private _reject: any;
	
	constructor() {
		this.initPromise();
	}

	initPromise(): void {
		this._result = new Promise((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });
	}

	show(): Promise<any> {
		this.boxEvents.emit({event: 'show'});
        return this._result;
	}

	hide(): void {
		this.boxEvents.emit({event: 'hide'});
		this._reject();
	}

	confirmed(): void {
		this._resolve();
		this.initPromise();
	}

}